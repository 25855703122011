import {http} from "../apis/request";
import {UserHome} from '../models/types';

class UserHomeService {
  get_user_home() {
    return http.get<UserHome>(`/api/user/home`);
  }
}

export default new UserHomeService();
