import {http} from "../apis/request";

interface ReqSetServiceState {
  open_kfid: string;
  external_userid: string;
  service_state: number;
}

interface ResSetServiceState {
}

class StateService {
  set_service_state(data: ReqSetServiceState) {
    return http.post<ResSetServiceState>(`/api/state`, data);
  }
}

export default new StateService();
